/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';

import { useMoengageEventTracker } from '../../../hooks/useMoengage';
import TrySectionRA from '../../../assets/images/TrySectionRA.svg';
import styles from './TryNewSection.module.scss';

function TryNewSection({ tryLearnSection }) {
  const router = useRouter();
  const tryNewSectionRef = React.useRef(null);
  const trackMoengageEvent = useMoengageEventTracker();

  useEffect(() => {
    const feedLanguageRegex = /^feeds\/all\/[a-zA-Z]+$/;
    const articlesRegex = /^articles\/(current-affairs)?$/;
    const isScrollEnable = sessionStorage.getItem('scrollToTryNew');
    const screenArray = sessionStorage.getItem('sourceScreen_Array')?.split(',') || [];
    const lastElement = screenArray?.length > 0 ? screenArray[screenArray?.length - 1] : null;
      if ((feedLanguageRegex.test(lastElement) ||
      articlesRegex.test(lastElement) || lastElement === 'dailyquizzes'
      || lastElement === 'free-live-classes' || lastElement === 'free-courses' || lastElement === 'studyiq-ai')
      && isScrollEnable === 'true') {
        setTimeout(() => {
            tryNewSectionRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
        }, 600);
        sessionStorage.removeItem('scrollToTryNew');
    }
  }, []);

  const handleBanner = (val) => {
    sessionStorage?.setItem('scrollToTryNew', 'true');
    if (val === 'studyiq-ai') {
      try {
        trackMoengageEvent('ncert_summary_tapped', {
          screen_source: 'home',
          position: 'try-learning-banner',
        });
      } catch (error) {
        /* empty */
      }
    }
    router.push(val);
  };

  return (
    <div data-testid="try-new-section" className={styles.tryNewOuterWrapper} ref={tryNewSectionRef}>
      <div className={styles.wrapper}>
        <div className={styles.bannerOuterWrapper}>
          <span className={styles.bannerTitle}>
            Try learning for
            <span className={styles.bannerSIQText}> free</span>
          </span>
          <span className={styles.bannerInnerText}>
            Unlock your true learning potential with the StudyIQ
          </span>
          <div className={styles.bannerInnerbuttons}>
            {tryLearnSection?.map((button, index) => (
              <button
                type="button"
                key={`${index + 1}-${button.id}`}
                className={styles.bannerStartNow}
                onClick={() => handleBanner(button?.redirectionUrl)}
                data-testid="try-new-button"
              >
                <div className={styles.tryNewButtons}>
                  <Image src={button?.iconUrl} alt="icon" width={40} height={40} />
                  <span className={styles.tryNewText}>{button?.name}</span>
                  <TrySectionRA />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TryNewSection;
